<template>
  <div class="login-container">
    <b-row>
      <b-col md="12">
        <div class="basic-1 title-1-bold-24 d-flex"
             style="justify-content: center; align-items: center; margin-bottom: 40px">
          {{ $t("AUTH.CREATE_SCHOOL_ACCOUNT") }}
        </div>
      </b-col>
      <b-col md="12">
        <form
          class="form sh-auth-form"
          novalidate="novalidate"
          id="sh_login_form"
        >
          <div class="form-floating mb-4">
            <input type="text"
                   class="form-control"
                   v-model="model[fields.email.name]"
                   :id="fields.email.name"
                   :placeholder="fields.email.label">
            <label for="floatingInput">{{ fields.email.label }}</label>
          </div>
          <div class="form-floating mb-4">
            <input type="text"
                   class="form-control"
                   v-model="model[fields.full_name.name]"
                   :id="fields.full_name.name"
                   :placeholder="fields.full_name.label">
            <label for="floatingInput">{{ fields.full_name.label }}</label>
          </div>
          <div class="form-floating mb-4" style="position: relative" :key="showPassword">
            <input v-if="showPassword" type="text"
                   class="form-control"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
            >
            <input v-else type="password"
                   class="form-control"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
            >
            <label for="floatingPassword">{{ fields.password.label }}</label>

            <div class="control">
              <unicon @click="() => showPassword = !showPassword"
                      :name="showPassword ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

            </div>
          </div>
          <div class="pb-lg-0 mb-4">
            <base-button
              block variant="secondary"
              :callback="doSubmit" callbackParams='mentor'>{{ $t('AUTH.SING_UP') }}
            </base-button>
          </div>

        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserModel from '@/modules/auth/user-model';
import { server } from '@/const';
import Notify from '@/shared/utils/notify';

const { fields } = UserModel;
export default {
  name: 'CreateAccount',
  data() {
    return {
      componentKey: 0,
      fields,
      model: {},
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    doSubmit(type) {
      const loginPath = '/WebRegister.php';
      const data = new FormData();
      data.append('email', this.model.email);
      data.append('name', this.model.full_name);
      data.append('password', this.model.password);
      data.append('type', type);
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            Notify('warning', json.error);
          } else {
            this.$router.push({ name: 'login' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/auth-layout.scss';

.login-container {
  height: 100%;
  margin: auto;

  .auth-forgot-password a {
    color: #1694D0 !important;
  }
  .control{
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: absolute;
    text-align: inherit;
    right: 16px;
    top: 20px;
    .button{
      display: flex;
    }
  }
}

.create-account{
  margin-top: 12px
}
.btn.btn-light.login-with-google{
  border: 0!important;
  color: #505161!important;
  font-size: 14px!important;
  display: flex;
  justify-content: center;
  font-weight: normal!important;
  img{
    width: 22px;
    height: 22px;
  }
}
.login-container{
@media (min-width: 768px) {
  .col-md-6{
    padding: 0 40px;
  }
  .first-col {
    border-right: 1px solid #E0E0E0;
  }
  .create-account{
    margin-top: 191px
  }

}}
.login-error-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #D66A5B;
  margin-bottom: 4px;
}
.form-control.login-error {
  border: 1px solid #D66A5B!important;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
}
</style>
